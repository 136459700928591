module.exports = [{
      plugin: require('/var/lib/jenkins/workspace/UseData-Home-Vn/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('/var/lib/jenkins/workspace/UseData-Home-Vn/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/UseData-Home-Vn/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/UseData-Home-Vn/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
