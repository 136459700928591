// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-text-page-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/templates/TextPage.jsx" /* webpackChunkName: "component---src-templates-text-page-jsx" */),
  "component---src-templates-narrow-text-page-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/templates/NarrowTextPage.jsx" /* webpackChunkName: "component---src-templates-narrow-text-page-jsx" */),
  "component---src-templates-help-article-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/templates/HelpArticle.jsx" /* webpackChunkName: "component---src-templates-help-article-jsx" */),
  "component---src-pages-about-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-case-studies-bubbleiq-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/case-studies/bubbleiq/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-bubbleiq-index-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-case-studies-stasher-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/case-studies/stasher/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-stasher-index-jsx" */),
  "component---src-pages-case-studies-studio-71-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/case-studies/studio71/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-studio-71-index-jsx" */),
  "component---src-pages-community-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/community/index.jsx" /* webpackChunkName: "component---src-pages-community-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/contact/thank-you.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-jsx" */),
  "component---src-pages-customers-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/customers/index.jsx" /* webpackChunkName: "component---src-pages-customers-index-jsx" */),
  "component---src-pages-help-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/help/index.jsx" /* webpackChunkName: "component---src-pages-help-index-jsx" */),
  "component---src-pages-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/integrations/index.jsx" /* webpackChunkName: "component---src-pages-integrations-index-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-product-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/product/index.jsx" /* webpackChunkName: "component---src-pages-product-index-jsx" */),
  "component---src-pages-services-cloud-computing-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/services/cloud-computing/index.jsx" /* webpackChunkName: "component---src-pages-services-cloud-computing-index-jsx" */),
  "component---src-pages-services-data-science-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/services/data-science/index.jsx" /* webpackChunkName: "component---src-pages-services-data-science-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-slack-failure-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/slack_failure/index.jsx" /* webpackChunkName: "component---src-pages-slack-failure-index-jsx" */),
  "component---src-pages-slack-success-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/slack_success/index.jsx" /* webpackChunkName: "component---src-pages-slack-success-index-jsx" */),
  "component---src-pages-slack-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/slack/index.jsx" /* webpackChunkName: "component---src-pages-slack-index-jsx" */),
  "component---src-pages-solutions-bubbleiq-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/solutions/bubbleiq/index.jsx" /* webpackChunkName: "component---src-pages-solutions-bubbleiq-index-jsx" */),
  "component---src-pages-solutions-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/solutions/index.jsx" /* webpackChunkName: "component---src-pages-solutions-index-jsx" */),
  "component---src-pages-solutions-stasher-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/solutions/stasher/index.jsx" /* webpackChunkName: "component---src-pages-solutions-stasher-index-jsx" */),
  "component---src-pages-solutions-studio-71-index-jsx": () => import("/var/lib/jenkins/workspace/UseData-Home-Vn/src/pages/solutions/studio71/index.jsx" /* webpackChunkName: "component---src-pages-solutions-studio-71-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/lib/jenkins/workspace/UseData-Home-Vn/.cache/data.json")

